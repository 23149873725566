.WhatIsAWalletPage {
  position: fixed;
  font-family: 'BIOS';

  width: 100vw;
  height: 100vh;

  top: 0px;
  left: 0px;

  background: white;
  z-index: 100;

  box-sizing: border-box;
  padding: 20px;
  overflow: scroll;
}

.BackButton {
  opacity: 0.3;
  cursor: pointer;
}

.BackButton:hover { 
  opacity: 0.7;
}

.WhatIsAWalletContainer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.WhatIsAWalletSummary {
  padding-top: 50px;
  font-family: 'BIOS';
  font-weight: 100;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #595959;
  text-align: center;
  line-height: 2.00;
}

.WhatIsAWalletTerminal {
  padding-top: 20px;
}

.WhatIsAWalletMenu {
  padding-top: 50px;
  max-width: 500px;
  font-family: 'BIOS';
  font-weight: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #A8A8A8;
}

.WhatIsAWalletMenu > * {
  margin-bottom: 30px;
  cursor: pointer;
}

.WhatIsAWalletSelect:hover {
  color: black;
  text-decoration: underline;
}

.WhatIsAWalletMenuTitle {
  margin-top: 45px;
  display: flex;
  justify-content: center;
  font-family: 'BIOS';
  text-decoration: underline;
  color: #A8A8A8;
  
}

.WhatIsAWalletMenuTitle > * {
  background: #000000;
  padding: 5px 10px 5px 10px;
}