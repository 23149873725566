.DOS {
  position: fixed;
  top: 0px;
  left: 0px;

  background: #000000;

  font-family: 'BIOS';
  color: #A8A8A8;

  width: 100vw;
  height: 100vh;
}

.HomePage {
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
}

.Line {
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
}

@keyframes dot_one {
  0% {
  	opacity: 0.0;
  }

  24% {
  	opacity: 0.0;
  }

  25% {
  	opacity: 1.0;
  }

  100% {
    opacity: 1.0;
  }
}

@keyframes dot_two {
  0% {
  	opacity: 0.0;
  }

  49% {
  	opacity: 0.0;
  }

  50% {
  	opacity: 1.0;
  }

  100% {
    opacity: 1.0;
  }
}

@keyframes dot_three {
  0% {
  	opacity: 0.0;
  }

  74% {
  	opacity: 0.0;
  }

  75% {
  	opacity: 1.0;
  }

  100% {
    opacity: 1.0;
  }
}

@keyframes loaded {
  0% {
  	opacity: 0.0;
  }

  99% {
  	opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}

.DotOne {
  animation-duration: 0.2s;
  animation-name: dot_one;
  animation-iteration-count: 2;
}

.DotTwo {
  animation-duration: 0.2s;
  animation-name: dot_two;
  animation-iteration-count: 2;
}

.DotThree {
  animation-duration: 0.2s;
  animation-name: dot_three;
  animation-iteration-count: 2;
}

.Loaded {
  animation-duration: 0.4s;
  animation-name: loaded;
  animation-iteration-count: 1;
}

.Content {
  position: fixed;

  width: 100vw;
  height: 100vh;

  top: 0px;
  left: 0px;

  background: white;
  z-index: 100;

  box-sizing: border-box;
  padding: 20px;

  animation-duration: 0.6s;
  animation-name: loaded;
  animation-iteration-count: 1;

  overflow-y: scroll;
}

.ContentTitle {
  font-family: 'BIOS';
  display: flex;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.ContentSubtitle {
  min-width: 160px;
}

.ContentBody {
  display: flex;
  justify-content: center;
}

.ContentMenu {
  padding-top: 50px;
  font-family: 'BIOS';
  font-weight: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #A8A8A8;
}

.ContentMenu > * {
  margin-bottom: 30px;
  cursor: pointer;
}

.ContextStatement {
  padding-top: 50px;
  font-family: 'BIOS';
  font-weight: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #595959;
  text-align: center;
  line-height: 2.00;
}

.ContentMenuTitle {
  margin-top: 45px;
  display: flex;
  justify-content: center;
  font-family: 'BIOS';
  text-decoration: underline;
  color: #A8A8A8;
  
}

.ContentMenuTitle > * {
  background: #000000;
  padding: 5px 10px 5px 10px;
}

.ContextStatement > * {
  margin-bottom: 30px;
}

.ContextStatementLine {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContextStatementLine > * {
  margin-right: 15px;
}

.ContentSelect:hover {
  color: black;
  text-decoration: underline;
}

.Rainbow {
  background-image: linear-gradient(to left, violet, indigo, blue, green, orange, red);   -webkit-background-clip: text;
  color: transparent;
  padding-right: 15px;
}
