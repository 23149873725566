.DOS {
  position: fixed;
  top: 0px;
  left: 0px;

  background: #000000;

  font-family: 'BIOS';
  color: #A8A8A8;

  width: 100vw;
  height: 100vh;
}

.FoodTracking {
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  position: relative;
}

.Content {
  position: fixed;

  width: 100vw;
  height: 100vh;

  top: 0px;
  left: 0px;

  background: white;
  z-index: 100;

  box-sizing: border-box;
  padding: 20px;

  animation-duration: 0.6s;
  animation-name: loaded;
  animation-iteration-count: 1;

  overflow-y: scroll;
}

.ContentTitle {
  font-family: 'BIOS';
  display: flex;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.ContentSubtitle {
  min-width: 160px;
}

.ContentBody {
  display: flex;
  justify-content: center;
}

.MacrosMacros > * {
  margin: 10px
}

.Dots {
  position: absolute;
  left: -46px;
  top: 18px;
  width: 20px;
  height: 20px;
  background: blue;
}

.TitleValue {
  font-family: 'BIOS';
  padding-bottom: 10px;
  padding-top: 20px
}

.CalorieValue {
  font-family: 'BIOS';
  font-size: 12px;
  color: #787878;
}

.HomeButton {
  font-family: 'BIOS';
  opacity: 0.3;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
  color: #000000;
  z-index: 9999999;
}

.HomeButton:hover { 
  opacity: 0.7;
}

.MacrosTitle {
  display: flex;
  flex-direction: column; 
  position: relative;
}

.MacrosCont {
  display: flex;
  justify-content: center;
}

.Macros {
  display: flex;
  height: 100%;
}

.Timeline {
  width: 30px;
  height: 10px;
}

.TimelineLineCont {
  padding-top: 30px;
  padding-bottom: 90px;
  box-sizing: border-box;
  height: 100%;
}

.TimelineLine {
  background: red;
  height: 100%;
  width: 8px;
  height: 100%;
}