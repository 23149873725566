.CryptoPage {
  position: fixed;
  font-family: 'BIOS';

  width: 100vw;
  height: 100vh;

  top: 0px;
  left: 0px;

  background: white;
  z-index: 100;

  box-sizing: border-box;
  padding: 20px;
  overflow: scroll;
}

.HomeButton {
  opacity: 0.3;
  cursor: pointer;
}

.HomeButton:hover { 
  opacity: 0.7;
}

.CryptoContainer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.CryptoSummary {
  padding-top: 50px;
  font-family: 'BIOS';
  font-weight: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #595959;
  text-align: center;
  line-height: 2.00;
}

.CryptoTerminal {
  padding-top: 20px;
}

.CryptoMenu {
  padding-top: 50px;
  max-width: 800px;
  font-family: 'BIOS';
  font-weight: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #A8A8A8;
}

.CryptoMenu > * {
  margin-bottom: 30px;
  cursor: pointer;
}

.CryptoSelect:hover {
  color: black;
  text-decoration: underline;
}

.CryptoMenuTitle {
  margin-top: 45px;
  display: flex;
  justify-content: center;
  font-family: 'BIOS';
  text-decoration: underline;
  color: #A8A8A8;
  
}

.CryptoMenuTitle > * {
  background: #000000;
  padding: 5px 10px 5px 10px;
}