.ErrorPage {
  font-family: 'BIOS';
  color: white;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #0000AA;
}

.Title {
  background: #AAAAAA;
  color: #0000AA;
  
  padding: 5px 10px 5px 10px;
  width: 120px;

  margin-bottom: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
}


.Line {
  margin-bottom: 30px;
}

@keyframes blink {
  0% {
  	opacity: 0.0;
  }

  51% {
  	opacity: 0.0;
  }

  50% {
  	opacity: 1.0;
  }

  100% {
    opacity: 1.0;
  }
}

.Blinking {
  padding-left: 30px;
  animation-duration: 1s;
  animation-name: blink;
  animation-iteration-count: infinite;
}

.AnyLineContinue {
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}