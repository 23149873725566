@import "node_modules/pixel-borders/src/styles/pixel-borders/pixel-borders-mixins";


.MacrosTitle {
  display: flex;
}

.MacrosTitleValue {
  flex-grow: 1;
}


.MacrosTitleTime {
  font-size: 10px;
  color: black;
}

.MacrosComponent {
  padding: 10px;
  font-family: 'BIOS';
  color: #A8A8A8;
  min-width: 400px;

  @include pixel-box(
    $corner-size: 1,
    $border-size: 4px,
    $background-color: #fff,
    $border-color: #000,
  );
}

.MacroComponent {
  display: flex;
  font-family: 'BIOS';
  color: #A8A8A8;
  font-size: 12px;
  padding-top: 10px;
}

.MacroLabel {
  color: #546590;
  width: 120px;
}

.MacroValueText {
  color: #000000;
}